.rockets {
  width: 90%;
  margin: 0 auto;
  padding: 0 5px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rockets .rocket {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

.rockets .rocket img {
  width: 300px;
  margin-right: 10px;
}

.rockets .rocket .content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 0;
  margin-left: 24px;
}

.rockets .rocket .content h2 {
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 5px;
}

.rockets .rocket .content p {
  font-size: 20px;
  margin-top: 0;
  width: 97%;
  line-height: 130%;
}

.rockets .rocket .content p .reserved {
  background: #18a4b9;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
  margin: 0 10px 0 0;
}

.rockets .rocket .content button {
  background-color: #007bff;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.rockets .rocket .content button.cancel-reservation {
  color: #18a4b9;
  border: 1px solid #18a4b9;
  background-color: #fff;
  font-weight: 500;
}

@media screen and (max-width: 991px) {
  .rockets .rocket {
    flex-direction: column;
    justify-content: flex-start;
  }

  .rockets .rocket .content {
    margin-left: 0;
    margin-top: 10px;
    align-items: flex-start;
  }
}

@media screen and (max-width: 576px) {
  .rockets .rocket {
    align-items: center;
  }

  .rockets .rocket .content {
    align-items: center;
    text-align: center;
  }
}
